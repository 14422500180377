<template>
  <div class="fixed z-10 bottom-20 right-4 p-2 flex items-center rounded-full bg-blue-500 animate-scale">
    <div class="flex items-center space-x-2">
      <div @click="showPhoneNumber" class="cursor-pointer bg-blue-700 p-2 rounded-full animate-scale">
        <img src="../assets/images/icons/icon-phone.webp" alt="icon-phone liên hệ dịch vụ thiết kế website" class="w-7 rounded-full">
      </div>
      <span v-if = isShow class="bg-yellow-200 text-rose-600 font-bold p-2 rounded-full animate-scale">
        0905 514 868
      </span>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';

const isShow = ref(false)
const showPhoneNumber = () => {
  isShow.value = !isShow.value;
}

</script>

<style scoped>
@keyframes scale {
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
}

.animate-scale {
  animation: scale 1.5s ease-in-out infinite;
}

</style>
