<template>
  <div class="fixed z-10 bottom-40 right-4 p-2 flex items-center rounded-full bg-blue-500 animate-scale">
    <div class="flex items-center space-x-2">
      <div class="cursor-pointer bg-blue-700 p-2 rounded-full animate-scale">
        <a href="https://zalo.me/0905514868" target="_blank">
          <img src="../assets/images/icons/icon-zalo.webp" alt="icon-zalo liên hệ dịch vụ thiết kế website" class="w-7 rounded-full">
        </a>
      </div>
    </div>
  </div>
</template>

<script setup>


</script>

<style scoped>
@keyframes scale {
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
}

.animate-scale {
  animation: scale 1.5s ease-in-out infinite;
}

</style>
