<template>
  <section class="bg-blue-600 text-white py-12">
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
      <h2 class="text-3xl font-bold mb-4">Ready to get started?</h2>
      <p class="mb-8">Contact us today to discuss your website design project.</p>
      <a href="/contact" class="bg-white text-blue-600 font-semibold py-3 px-6 rounded-lg shadow-md hover:bg-gray-200">
        Contact now
      </a>
    </div>
  </section>
</template>
