import { createRouter, createWebHistory } from 'vue-router';
import Home from '../components/Home.vue';
import About from '../components/About.vue';
import Other from '../components/Other.vue';
import WebGuide from '../components/WebGuide.vue';
import Contact from '../components/Contact.vue';

import SalesWebsite from '../components/ServicesDetail/SalesWebsite.vue';
import LandingDesignWebsite from '../components/ServicesDetail/LandingDesignWebsite.vue';
import EcomSiteWebsite from '../components/ServicesDetail/EcomSiteWebsite.vue';
import BlogDesignWebsite from '../components/ServicesDetail/BlogDesignWebsite.vue';
import StudentHelp from '../components/ServicesDetail/StudentHelp.vue';
import AdServices from '../components/ServicesDetail/AdServices.vue';

import WorkingProcess from '@/components/WorkingProcess.vue';
import NotFound from '@/components/404NotFound.vue';


const routes = [
    {
        path: '/',
        component: Home
    },
    {
        path: '/about',
        component: About
    },
    {
        path: '/contact',
        component: Contact
    },
    {
        path: '/web-guide',
        component: WebGuide
    },
    {
        path: '/other-services',
        component: Other
    },
    {
        path: '/thiet-ke-website-ban-hang-ca-nhan',
        component: SalesWebsite
    },
    {
        path: '/thiet-ke-website-landing-page',
        component: LandingDesignWebsite
    },
    {
        path: '/thiet-ke-website-thuong-mai-dien-tu',
        component: EcomSiteWebsite
    },
    {
        path: '/thiet-ke-website-blog',
        component: BlogDesignWebsite
    },
    {
        path: '/thiet-ke-do-an-website',
        component: StudentHelp
    },
    {
        path: '/chay-quang-cao-website',
        component: AdServices
    },
    {
        path: '/quy-trinh-thiet-ke-website',
        component: WorkingProcess
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'NotFound',
        component: NotFound
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
    // Click vào một đường link trong Vue Router và trang tự động cuộn lên đầu trang
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
          return savedPosition;
        } else {
          return { top: 0 };
        }
      },
});

export default router;
