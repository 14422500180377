<template>
  <div class="container flex flex-col items-center mx-auto p-4">
    <div class="text-center mb-8">
      <img src="@/assets/images/teams/our-teams.webp" alt="Meet our professional web development team" class="w-[400px] h-[300px] object-cover">
      <h2 class="text-2xl lg:text-3xl font-semibold text-blue-600">
        Meet Our Professional Website Design Team
      </h2>
    </div>

    <div class="flex flex-wrap justify-center">
      <div class="sm:w-1/2 lg:w-1/4 p-4 flex justify-center">
        <div class="text-center">
          <img src="@/assets/images/teams/giang.webp" alt="Trần Trường Giang - Manager and Web Developer" class="custom w-[250px] h-[300px] object-cover rounded-lg shadow-lg">
          <h3 class="text-lg font-semibold text-gray-800 mt-4">Trần Trường Giang</h3>
          <h3 class="text-sm text-gray-600">Manager, Web Developer</h3>
        </div>
      </div>

      <div class="sm:w-1/2 lg:w-1/4 p-4 flex justify-center">
        <div class="text-center">
          <img src="@/assets/images/teams/tam.webp" alt="Bạch Ngọc Tâm - Web Developer" class="custom w-[250px] h-[300px] object-cover rounded-lg shadow-lg">
          <h3 class="text-lg font-semibold text-gray-800 mt-4">Bạch Ngọc Tâm</h3>
          <h3 class="text-sm text-gray-600">Web Developer</h3>
        </div>
      </div>

      <div class="sm:w-1/2 lg:w-1/4 p-4 flex justify-center">
        <div class="text-center">
          <img src="@/assets/images/teams/nguyen.webp" alt="Nguyễn Thảo Nguyên - Designer and Website Tester" class="custom w-[250px] h-[300px] object-cover rounded-lg shadow-lg">
          <h3 class="text-lg font-semibold text-gray-800 mt-4">Nguyễn Thảo Nguyên</h3>
          <h3 class="text-sm text-gray-600">Designer, Tester</h3>
        </div>
      </div>

      <div class="sm:w-1/2 lg:w-1/4 p-4 flex justify-center">
        <div class="text-center">
          <img src="@/assets/images/teams/nam.webp" alt="Nguyễn Hữu Nam - SEO Specialist for website optimization" class="custom w-[250px] h-[300px] object-cover rounded-lg shadow-lg">
          <h3 class="text-lg font-semibold text-gray-800 mt-4">Nguyễn Hữu Nam</h3>
          <h3 class="text-sm text-gray-600">SEO Specialist, Web Optimization</h3>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.custom {
  transition: all 0.3s ease-in-out;
}

.custom:hover {
  cursor: pointer;
  transform: scale(1.1);
}

</style>