<template>
<div>
    <section class="px-4 py-12 bg-gray-50">
    <div class="max-w-7xl mx-auto">
      <h1 class="text-3xl font-bold text-center mb-8 text-blue-600">Quy trình thiết kế website</h1>
      <div class="space-y-3">
        <!-- Step 1 -->
        <div class="flex items-start space-x-4">
          <div class="flex-shrink-0">
            <div class="w-8 h-8 md:w-12 md:h-12 lg:w-12 lg:h-12 rounded-full bg-indigo-500 text-white flex items-center justify-center font-bold text-lg">
              1
            </div>
          </div>
          <div class="pt-1.7 md:pt-1.5 lg:pt-1.5">
            <h2 class="text-xl font-semibold">Tiếp nhận và tư vấn chi tiết</h2>
            <p class="text-gray-600 mt-2">
              Chúng tôi lắng nghe yêu cầu của bạn và tư vấn chi tiết về giải pháp thiết kế website chuyên nghiệp. Nếu đồng ý, chúng ta sẽ ký hợp đồng. Bạn có quyền không ký nếu chưa đạt thỏa thuận.
            </p>
          </div>
        </div>

        <!-- Step 2 -->
        <div class="flex items-start space-x-4">
          <div class="flex-shrink-0">
            <div class="w-8 h-8 md:w-12 md:h-12 lg:w-12 lg:h-12 rounded-full bg-indigo-500 text-white flex items-center justify-center font-bold text-lg">
              2
            </div>
          </div>
          <div class="pt-1.7 md:pt-1.5 lg:pt-1.5">
            <h2 class="text-xl font-semibold">Ký hợp đồng và đặt cọc</h2>
            <p class="text-gray-600 mt-2">
              Sau khi ký hợp đồng, bạn thanh toán 20% phí đặt cọc. Chúng tôi sẽ bắt đầu quá trình thiết kế website theo yêu cầu của bạn.
            </p>
          </div>
        </div>

        <!-- Step 3 -->
        <div class="flex items-start space-x-4">
          <div class="flex-shrink-0">
            <div class="w-8 h-8 md:w-12 md:h-12 lg:w-12 lg:h-12 rounded-full bg-indigo-500 text-white flex items-center justify-center font-bold text-lg">
              3
            </div>
          </div>
          <div class="pt-1.7 md:pt-1.5 lg:pt-1.5">
            <h2 class="text-xl font-semibold">Báo cáo tiến độ dự án</h2>
            <p class="text-gray-600 mt-2">
              Tiến độ dự án sẽ được báo cáo qua Google Sheets, cho phép cả hai bên theo dõi và cập nhật kịp thời.
            </p>
          </div>
        </div>

        <!-- Step 4 -->
        <div class="flex items-start space-x-4">
          <div class="flex-shrink-0">
            <div class="w-8 h-8 md:w-12 md:h-12 lg:w-12 lg:h-12 rounded-full bg-indigo-500 text-white flex items-center justify-center font-bold text-lg">
              4
            </div>
          </div>
          <div class="pt-1.7 md:pt-1.5 lg:pt-1.5">
            <h2 class="text-xl font-semibold">Trao đổi trong quá trình thiết kế</h2>
            <p class="text-gray-600 mt-2">
              Trao đổi qua Zalo 0905514868 để đảm bảo mọi vấn đề thiết kế website được xử lý nhanh chóng.
            </p>
          </div>
        </div>

        <!-- Step 5 -->
        <div class="flex items-start space-x-4">
          <div class="flex-shrink-0">
            <div class="w-8 h-8 md:w-12 md:h-12 lg:w-12 lg:h-12 rounded-full bg-indigo-500 text-white flex items-center justify-center font-bold text-lg">
              5
            </div>
          </div>
          <div class="pt-1.7 md:pt-1.5 lg:pt-1.5">
            <h2 class="text-xl font-semibold">Hoàn thành và bàn giao website</h2>
            <p class="text-gray-600 mt-2">
              Bạn thanh toán 80% còn lại khi dự án hoàn thành. Chúng tôi sẽ bàn giao website đã tối ưu SEO cho bạn.
            </p>
          </div>
        </div>

        <!-- Step 6 -->
        <div class="flex items-start space-x-4">
          <div class="flex-shrink-0">
            <div class="w-8 h-8 md:w-12 md:h-12 lg:w-12 lg:h-12 rounded-full bg-indigo-500 text-white flex items-center justify-center font-bold text-lg">
              6
            </div>
          </div>
          <div class="pt-1.7 md:pt-1.5 lg:pt-1.5">
            <h2 class="text-xl font-semibold">Hỗ trợ sau bàn giao</h2>
            <p class="text-gray-600 mt-2">
              Đối với trang web được code tay 100%, chúng tôi xử lý mọi vấn đề kỹ thuật miễn phí. Nâng cấp hoặc thêm tính năng mới sẽ được tính phí tùy theo mức độ.
            </p>
          </div>
        </div>
      </div>
      <div class="mt-12 text-center">
        <p class="text-gray-600">Chúng tôi trân trọng sự hợp tác và tin tưởng của bạn!</p>
      </div>
    </div>
  </section>
</div>
</template>

