<template>
  <div class="relative h-[50px] flex justify-evenly items-center bg-orange-400 font-semibold border-animation">
    <h1 class="hidden lg:block text-[20px]">Web services</h1>
    <h1 class="text-[14px] sm:text-[20px] highlight-text">THIẾT KẾ WEBSITE CHUYÊN NGHIỆP - TỐI ƯU SEO</h1>
    <h1 class="hidden lg:block text-[20px]">Hotline: 0905 514 868</h1>
  </div>
</template>
<style scoped>
.border-animation {
  border: 2px dashed;
  animation: border_animate 1s infinite ease-in-out;
}

@keyframes border_animate {
  0% {
    border-color: transparent;
  }
  50% {
    border-color: rgb(0, 238, 255);
  }
  100% {
    border-color: transparent;
  }
}


.highlight-text {
  background: linear-gradient(90deg, red, yellow, green, cyan, blue, purple);
  background-size: 300%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: gradient-animation 2s infinite;
}

@keyframes gradient-animation {
    0% {
        background-position: 0% 50%;
    }
    100% {
        background-position: 100% 50%;
    }
}

</style>
